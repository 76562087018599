<template>
  <div
    class="dlp-timeline"
    v-bind="$attrs"
  >
    <Swiper
      ref="dlpTimeline"
      :options="timelineOptions"
      class="dlp-timeline-items"
    >
      <slot />
      <template #button-prev>
        <div
          :id="`dlp-slider-prev-${swiperId}`"
          class="swiper-button-prev swiper-nav-button"
        >
          <svg>
            <use xlink:href="#slider-nav-arrow" />
          </svg>
        </div>
      </template>
      <template #button-next>
        <div
          :id="`dlp-slider-next-${swiperId}`"
          class="swiper-button-next swiper-nav-button"
        >
          <svg>
            <use xlink:href="#slider-nav-arrow" />
          </svg>
        </div>
      </template>
    </Swiper>
    <div class="dlp-timeline--line" />
  </div>
</template>
<script>
export default {
  name: 'DlpTimeline',
  props: {
    options: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data() {
    const _id = 'swiper' + Math.round(Math.random() * 100000);
    return {
      swiperId: _id,
      timelineOptions: {
        ...this.options,
        slidesPerView: 'auto',
        navigation: {
          nextEl: `#dlp-slider-next-${_id}`,
          prevEl: `#dlp-slider-prev-${_id}`,
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.dlpTimeline.$swiper;
    },
  },
};
</script>
