import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config.js';

/**
 * @TODO - shim for useTailwindBreakpoints composable. switch to that if DLP ever upgrades to vue3
 */
class TailwindBreakpointService {
    isMobile() {
        return this.isBreakpoint('sm');
    }

    isBreakpoint(breakpoint) {
        const screens = resolveConfig(tailwindConfig).theme.screens;
        const breakpointValue = screens[breakpoint];
        if (!breakpointValue) {
            return false;
        }
        return window.matchMedia(`(max-width: ${breakpointValue})`).matches;
    }
}

export default new TailwindBreakpointService();
