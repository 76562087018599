<template>
  <div class="section-content">

    <section
      v-for="(section, key, index) in sectionsToDisplay"
      :key="`closing-${key}`"
      class="content-grid"
    >
      <div
        :class="{
          'order-1 md:order-2 col-span-4 md:col-span-6 md:col-start-7 lg:col-span-7 lg:col-start-9 self-center': index % 2,
          'order-1 md:order-1 col-span-full md:col-span-6 lg:col-span-7 lg:col-start-2 self-center': !(index % 2),
        }"
      >
        <dlp-figure
          :sm="section.image"
          :alt="section.alt"
          animate="shrink"
          ar="ar--4-3"
        />
      </div>
      <div
        :class="{
          'order-2 md:order-1 col-span-4 md:col-span-6 md:col-start-1 lg:col-span-6 lg:col-start-2 self-center': index % 2,
          'order-2 md:order-2 col-span-full md:col-span-6 md:col-start-7 lg:col-span-6 lg:col-start-10 self-center': !(index % 2),
        }"
      >
        <h3 class="head-5 accent--above-left fade-in">{{ section.heading }}</h3>
        <p class="fade-in">{{ section.content }}</p>
        <div class="btn-group fade-in" v-if="section.button?.active">
          <dlp-button
            type="button"
            @click="handleClick(section)"
          >
            {{ section.button.buttonText }}
          </dlp-button>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import { pickBy } from 'lodash';

export default {
  name: 'closingContainer',
  data: () => ({
    i18n: window.appData.closing,
    sections: [
      'article',
      'zenlist',
      'atTurfReport',
      'marketReport',
      'closingMilestones',
    ],
  }),
  computed: {
    sectionsToDisplay() {
      return pickBy(this.i18n, (sectionData, sectionName) => this.sections.includes(sectionName) && sectionData.active);
    },
  },
  methods: {
    showModal: function(name, evt) {
      if(this.$refs[name]){
        this.$refs[name].open(evt);
      }
    },

    handleClick: function(section) {
      if (!section?.active || !section?.button?.active || !section?.button?.url?.length) {
        return;
      }

      // Handle download
      if (section.button?.filename?.length) {
        const anchor = document.createElement('a');
        anchor.href = section.button.url;
        anchor.target = '_blank';
        anchor.download = section.button.filename;
        anchor.click();
        return;
      }

      window.open(section.button.url, '_blank');
    },
  },
}
</script>
