<template>
  <div
    v-if="i18n.name"
    class="section-content"
  >
    <div class="content-grid">
      <div
        v-if="i18n.logo.alt"
        class="col-span-full lg:col-span-14 lg:col-start-2 text-center"
      >
        <img
          class="mx-auto logo--agency fade-in"
          :src="`${i18n.logo.sm}`"
          :alt="`${i18n.logo.alt}`"
        >
      </div>
      <div class="col-span-full px-12 sm:px-0 sm:col-span-2 sm:col-start-2 md:col-span-4 lg:col-start-2 lg:col-span-5">
        <dlp-figure
          :sm="`${i18n.photo.sm}`"
          :md="`${i18n.photo.md}`"
          :alt="`${i18n.name}`"
          :caption="`${i18n.name}`"
          ar="ar ar--4-5"
          :is-sticky="false"
          animate="shrink"
        />
      </div>
      <div class="col-span-full md:col-span-7 lg:col-start-9 lg:col-span-7">
        <section>
          <h1 class="head-1 fade-in">
            {{ i18n.name }}
          </h1>
          <h4
            v-if="i18n.showAgentTitle && (i18n.title && i18n.title.length)"
            class="head-4 fade-in"
          >
            {{ i18n.title }}
          </h4>
          <p
            v-if="i18n.showDesignations && (i18n.designationList && i18n.designationList.length)"
            class="fade-in mt-2"
          >
            {{ i18n.designationList }}
          </p>
          <p
            v-if="i18n.licenses && i18n.licenses.length"
            class="mt-0"
          >
            License No. {{ i18n.licenses }}
          </p>
          <h2 class="head-5 accent--above-left fade-in">
            Bio
          </h2>
          <p
            class="fade-in"
            v-html="i18n.bio.brief"
          />
          <div v-if="i18n.showOfficeInfo">
            <h5 class="head-5 mt-4">
              Office Location
            </h5>
            <p
              class="mt-0"
              v-text="streetAddress"
            />
            <p
              class="mt-0"
              v-text="cityStateZip"
            />
            <a
              class="mt-0"
              :href="`tel:+1-${i18n.officePhone}`"
            >
              <p class="mt-0">{{ i18n.officePhone }}</p>
            </a>
          </div>
          <div
            v-if="i18n.coAgentBioModal.content || i18n.video"
            class="btn-group fade-in"
          >
            <dlp-button
              v-if="i18n.coAgentBioModal.content"
              type="button"
              @click="showModal(i18n.coAgentBioModal.type, $event)"
            >
              {{ i18n.coAgentBioModal.buttonText }}
            </dlp-button>
            <dlp-button
              v-if="i18n.video"
              type="button"
              @click="showModal(i18n.coAgentVideoModal.type, $event)"
            >
              {{ i18n.coAgentVideoModal.buttonText }}
            </dlp-button>
          </div>
          <dlp-appear-modal
            i18n.coAgentBioModal.content
            ref="CoagentModal"
          >
            <h3 class="head-5 accent--above-left mb-8">
              {{ i18n.coAgentBioModal.heading }}
            </h3>
            <div v-html="i18n.coAgentBioModal.content" />
          </dlp-appear-modal>
          <dlp-video-modal
            v-if="i18n.video"
            ref="TeamVideo"
            :url="i18n.video"
            :caption="i18n.name"
          />
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CoagentContainer',
  data() {
    return {
      i18n: window.appData.coagent,
    };
  },
  computed: {
    streetAddress() {
      return [this.i18n.officeLocation.address1, this.i18n.officeLocation.address2].filter(addressPart => addressPart && addressPart.length).join(', ');
    },
    cityStateZip () {
      const retStr = (this.i18n.officeLocation.city || '')
          + (this.i18n.officeLocation.city && this.i18n.officeLocation.state ? ', ' : '')
          + (this.i18n.officeLocation.state || '')
          + ' '
          + (this.i18n.officeLocation.zip || '');
      return retStr.trim();
    },
  },
  methods: {
    showModal: function(name, evt) {
      if (this.$refs[name]) {
        this.$refs[name].open(evt);
      }
    },
  },
};
</script>
