<template>
    <div class='section-content'>
      <section v-if="i18n.article.active" class="content-grid">
        <div class="order-1 md:order-2 col-span-4 md:col-span-6 md:col-start-7 lg:col-span-7 lg:col-start-9 self-center">
          <dlp-figure
            :sm="i18n.article.image"
            :alt="i18n.article.alt"
            ar="ar ar--4-3"
            animate="shrink"
          />
        </div>
        <div class="order-2 md:order-1 col-span-4 md:col-span-6 md:col-start-1 lg:col-span-6 lg:col-start-2 self-center">
          <h3 class="head-5 accent--above-left fade-in tenant-case">{{ i18n.article.heading }}</h3>
          <p class="fade-in">{{ i18n.article.content }}</p>
        </div>
      </section>
      <section v-if="i18n.article2.active" class="content-grid">
        <div class="order-1 md:order-1 col-span-full md:col-span-6 lg:col-span-7 lg:col-start-2 self-center">
          <dlp-figure
            :sm="i18n.article2.image"
            :alt="i18n.article2.alt"
            ar="ar ar--4-3"
            animate="shrink"
          />
        </div>
        <div class="order-2 md:order-2 col-span-full md:col-span-6 md:col-start-7 lg:col-span-6 lg:col-start-10 self-center">
          <h3 class="head-5 accent--above-left fade-in tenant-case">{{ i18n.article2.heading }}</h3>
          <p class="fade-in">{{ i18n.article2.content }}</p>
        </div>
      </section>
      <section v-if="i18n.article3.active" class="content-grid">
        <div class="order-1 md:order-2 col-span-4 md:col-span-6 md:col-start-7 lg:col-span-7 lg:col-start-9 self-center">
          <dlp-figure
            :sm="i18n.article3.image"
            :alt="i18n.article3.alt"
            ar="ar ar--4-3"
            animate="shrink"
          />
        </div>
        <div class="order-2 md:order-1 col-span-4 md:col-span-6 md:col-start-1 lg:col-span-6 lg:col-start-2 self-center">
          <h3 class="head-5 accent--above-left fade-in tenant-case">{{ i18n.article3.heading }}</h3>
          <p class="fade-in">{{ i18n.article3.content }}</p>
        </div>
      </section>
    </div>
</template>
<script>
export default {
    name: 'technologyContainer',
    data(){
        return {
            publicPath: process.env.BASE_URL,
            i18n: window.appData.technology,
        }
    },
}
</script>
