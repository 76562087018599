<template>
  <div id="app">
    <component
      v-if="presentationContainer"
      :is="presentationContainer"
    />
    <div v-else>
      An error occurred when trying to load the presentation. Please try again later.
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger"
import ExpoScaleEase from "gsap/EasePack";
import MotionPathPlugin from "gsap/MotionPathPlugin";

import ListingPresentationContainer from './containers/listingPresentation.container'
import BuyerPresentationContainer from "./containers/buyerPresentation.container";

import './assets/base.styles.scss'

gsap.registerPlugin(MotionPathPlugin, ScrollTrigger, ExpoScaleEase);

export default {
  name: 'App',
  components: {
    BuyerPresentationContainer,
    ListingPresentationContainer,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      appData: window.appData,
    }
  },
  computed: {
    presentationContainer: function () {
      switch(this.appData.presentationType) {
        case 'listing':
          return ListingPresentationContainer;
        case 'buyer':
          return BuyerPresentationContainer;
        default:
          return null;
      }
    }
  },
  mounted() {
    //generic fade-in 
    gsap.utils.toArray(".fade-in").forEach(item => {
      gsap.to(item, {
        scrollTrigger: {
          trigger: item,
          start: "top 90%",
          end: "top center",
          scrub: 1,
          once: true
        },
        opacity: 1
      })

      document.title = `${this.appData.agent.name} - ${this.appData.company.name}`;
    });

  }
}
</script>
