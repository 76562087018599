<template>
  <div class="section-content">
    <section
      v-if="i18n.reach.active"
      class="content-grid"
    >
      <div class="col-span-full md:col-span-6 lg:col-span-7 lg:col-start-2">
        <dlp-figure
          :sm="i18n.reach.image"
          :alt="i18n.reach.alt"
          ar="ar ar--4-3"
          animate="shrink"
        />
      </div>
      <div class="col-span-full md:col-span-6 md:col-start-7 lg:col-span-6 lg:col-start-10 self-center">
        <h3 class="head-5 accent--above-left fade-in">
          {{ i18n.reach.heading }}
        </h3>
        <p class="fade-in">
          {{ i18n.reach.content }}
        </p>
        <div
          v-if="i18n.reach.modal.active"
          class="btn-group fade-in"
        >
          <dlp-button
            type="button"
            @click="showModal('ReachStatsModal', $event)"
          >
            {{ i18n.reach.modal.buttonText }}
          </dlp-button>
        </div>
        <dlp-image-modal ref="ReachStatsModal">
          <h3 class="head-5 accent--above-left mb-8">
            {{ i18n.reach.modal.buttonText }}
          </h3>
          <img
            :src="i18n.reach.modal.image"
            :alt="i18n.reach.modal.buttonText"
          >
        </dlp-image-modal>
      </div>
    </section>

    <section
      v-if="i18n.portfolio.active"
      class="content-grid"
    >
      <div class="order-3 md:order-4 col-span-4 md:col-span-6 md:col-start-7 lg:col-span-7 lg:col-start-9">
        <dlp-figure
          :sm="i18n.portfolio.image"
          :alt="i18n.portfolio.alt"
          ar="ar ar--4-3"
          animate="shrink"
        />
      </div>
      <div
        class="order-4 md:order-3 col-span-4 md:col-span-6 md:col-start-1 lg:col-span-6 lg:col-start-2 content-center self-center"
      >
        <h3 class="head-5 accent--above-left fade-in">
          {{ i18n.portfolio.heading }}
        </h3>
        <p class="fade-in">
          {{ i18n.portfolio.content }}
        </p>
        <div
          v-if="i18n.portfolio.modal.active"
          class="btn-group fade-in"
        >
          <dlp-button
            type="button"
            @click="showModal(i18n.portfolio.modal.type, $event)"
          >
            {{ i18n.portfolio.modal.buttonText }}
          </dlp-button>
        </div>
        <dlp-appear-modal
          ref="LuxaryStatsModal"
          wide
        >
          <h3 class="head-5 accent--above-left mb-8">
            {{ i18n.portfolio.modal.heading }}
          </h3>
          <p
            v-for="(image, key) in i18n.portfolio.modal.images"
            :key="key"
          >
            <img
              :src="image"
              alt="Luxury stats"
            >
          </p>
        </dlp-appear-modal>
      </div>
    </section>

    <section
      v-if="i18n.video.active"
      id="luxury-video"
      class="content-grid"
    >
      <div class="col-span-full text-center">
        <h3 class="head-2 accent--below-center accent--mb-0">
          {{ i18n.video.heading }}
        </h3>
      </div>
      <div class="col-span-full lg:col-span-12 lg:col-start-3 lg:mt-4">
        <dlp-video-card
          v-if="i18n.video.video"
          :sm="i18n.video.image"
          :alt="i18n.video.alt"
          animate="shrink"
          :url="i18n.video.video"
          :title="i18n.video.title"
        />
        <dlp-figure
          v-if="!i18n.video.video"
          :sm="i18n.video.image"
          :alt="i18n.video.alt"
          ar="ar ar--4-3"
          animate="shrink"
        />
      </div>
      <div class="col-span-full md:col-span-8 md:col-start-3 lg:col-span-6 lg:col-start-6">
        <p class="accent--above-left">
          {{ i18n.video.content }}
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'GlobalreachContainer',
  data() {
    return {
      i18n: window.appData.reach,
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {
    showModal: function (name, evt) {
      if (this.$refs[name]) {
        this.$refs[name].open(evt);
      }
    },
  },
};
</script>
