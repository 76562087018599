<template>
    <div class="section-content">

        <section class="content-grid" v-if="i18n.pricing.active">
            <div class="order-3 md:order-4 col-span-4 md:col-span-6 md:col-start-7 lg:col-span-7 lg:col-start-9">
              <dlp-figure
                  :sm="i18n.pricing.image"
                  :alt="i18n.pricing.alt"
                  animate="shrink"
                  ar="ar--4-3"
              />
            </div>
            <div class="order-4 md:order-3 col-span-4 md:col-span-6 md:col-start-1 lg:col-span-6 lg:col-start-2">
                <h3 class="head-5 accent--above-left fade-in">{{ i18n.pricing.heading }}</h3>
                <p class="fade-in">{{ i18n.pricing.content }}</p>
                <div class="btn-group fade-in" v-if="i18n.pricing.cmaUrl">
                    <dlp-button type="button"
                                @click="openCma()">{{ i18n.pricing.modal.buttonText }}
                    </dlp-button>
                </div>
            </div>
        </section>

        <section class="content-grid" v-if="i18n.selling.active">
            <div class="col-span-4 md:col-span-6 md:col-start-1 lg:col-span-7 lg:col-start-2 self-center">
                <dlp-figure
                    :sm="i18n.selling.image"
                    :alt="i18n.selling.alt"
                    animate="shrink"
                    ar="ar--4-3"
                />
            </div>
            <div class="col-span-4 md:col-span-6 md:col-start-7 lg:col-span-6 lg:col-start-10 self-center">
                <h3 class="head-5 accent--above-left fade-in">{{ i18n.selling.heading }}</h3>
                <p class="fade-in">{{ i18n.selling.content }}</p>
                <div class="btn-group fade-in" v-if="i18n.selling.modal.active">
                    <dlp-button type="button" @click="showModal(i18n.selling.modal.type, $event)">{{ i18n.selling.modal.buttonText}}</dlp-button>
                </div>
                <dlp-appear-modal ref="LearnModal">
                    <h3 class="head-5 accent--above-left mb-8">{{ i18n.selling.modal.heading }}</h3>
                    <ul>
                      <li v-for="(item, key) in i18n.selling.modal.content" :key="key">{{item}}</li>
                    </ul>
                </dlp-appear-modal>
            </div>
        </section>

    </div>
</template>


<script>
export default {
    name: 'sellingContainer',
    data: () => ({
      i18n: window.appData.selling,
    }),
    methods: {
        showModal: function(name, evt) {
            if(this.$refs[name]){
                this.$refs[name].open(evt)
            }
        },
        openCma: function() {
            window.open(this.i18n.pricing.cmaUrl, '_blank');
        }
    }
}
</script>
