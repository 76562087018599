var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-content"},_vm._l((_vm.sectionsToDisplay),function(section,key,index){return _c('section',{key:`closing-${key}`,staticClass:"content-grid"},[_c('div',{class:{
        'order-1 md:order-2 col-span-4 md:col-span-6 md:col-start-7 lg:col-span-7 lg:col-start-9 self-center': index % 2,
        'order-1 md:order-1 col-span-full md:col-span-6 lg:col-span-7 lg:col-start-2 self-center': !(index % 2),
      }},[_c('dlp-figure',{attrs:{"sm":section.image,"alt":section.alt,"animate":"shrink","ar":"ar--4-3"}})],1),_c('div',{class:{
        'order-2 md:order-1 col-span-4 md:col-span-6 md:col-start-1 lg:col-span-6 lg:col-start-2 self-center': index % 2,
        'order-2 md:order-2 col-span-full md:col-span-6 md:col-start-7 lg:col-span-6 lg:col-start-10 self-center': !(index % 2),
      }},[_c('h3',{staticClass:"head-5 accent--above-left fade-in"},[_vm._v(_vm._s(section.heading))]),_c('p',{staticClass:"fade-in"},[_vm._v(_vm._s(section.content))]),(section.button?.active)?_c('div',{staticClass:"btn-group fade-in"},[_c('dlp-button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.handleClick(section)}}},[_vm._v(" "+_vm._s(section.button.buttonText)+" ")])],1):_vm._e()])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }