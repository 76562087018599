<template>
  <div class="section-content">
    <section
      v-if="i18n.contact.active"
      class="content-grid pb-12 lg:pb-16"
      aria-label="Agent contact information"
    >
      <div
        v-if="i18n.agent.logo.alt"
        class="col-span-full lg:col-span-14 lg:col-start-2 text-center"
      >
        <img
          :src="i18n.agent.logo.sm"
          class="mx-auto logo--agency fade-in"
          :alt="i18n.agent.logo.alt"
        >
      </div>
      <div class="col-span-full px-12 sm:px-0 sm:col-span-2 sm:col-start-2 md:col-span-4 lg:col-start-2 lg:col-span-5">
        <dlp-figure
          :sm="`${i18n.agent.photo.sm}`"
          :md="`${i18n.agent.photo.md}`"
          :alt="`${i18n.agent.name}`"
          :caption="`${i18n.agent.name}`"
          ar="ar ar--4-5"
          animate="shrink"
        />
      </div>
      <div class="col-span-full md:col-span-7 lg:col-start-9 lg:col-span-7">
        <h1 class="head-1 fade-in">
          {{ i18n.agent.name }}
        </h1>
        <h4
          v-if="i18n.agent.showAgentTitle && (i18n.agent.title && i18n.agent.title.length)"
          class="head-4 fade-in"
        >
          {{ i18n.agent.title }}
        </h4>
        <p
          v-if="i18n.agent.showDesignations && (i18n.agent.designationList && i18n.agent.designationList.length)"
          class="fade-in mt-2"
        >
          {{ i18n.agent.designationList }}
        </p>
        <p
          v-if="i18n.agent.licenses && i18n.agent.licenses.length"
          class="mt-0"
        >
          License No. {{ i18n.agent.licenses }}
        </p>
        <h5 class="head-5 accent--above-left fade-in">
          Contact Me
        </h5>
        <address class="contact-info fade-in">
          <a :href="`tel:+1-${i18n.agent.contact.phone}`">{{ i18n.agent.contact.phone }}</a>
          <a :href="`mailto:${i18n.agent.contact.email}`">{{ i18n.agent.contact.email }}</a>
        </address>
        <dlp-social
          class="fade-in"
          v-bind="i18n.agent.contact.social"
        />

        <div v-if="i18n.agent.showOfficeInfo">
          <h5 class="head-5 mt-4">
            Office Location
          </h5>
          <p
            class="mt-0"
            v-text="streetAddress"
          />
          <p
            class="mt-0"
            v-text="cityStateZip"
          />
        </div>
        <a
          class="mt-0"
          :href="`tel:+1-${i18n.agent.officePhone}`"
        >
          <p class="mt-0">{{ i18n.agent.officePhone }}</p>
        </a>
      </div>
    </section>

    <section
      class="content-grid border-t border-b border-primary-200 py-12 lg:py-16"
      aria-label="@properties social presence"
    >
      <div class="col-span-full w-4/5 mx-auto md:w-full md:col-span-8 md:col-start-3 lg:col-span-6 lg:col-start-6 mb-8">
        <img
          :src="i18n.contact.logo"
          class="w-full"
          :alt="i18n.contact.alt"
        >
      </div>
      <div class="col-span-full lg:col-span-14 lg:col-start-2 text-center">
        <dlp-social
          class="fade-in"
          :facebook="i18n.contact.contactLinks.facebook"
          :instagram="i18n.contact.contactLinks.instagram"
          :twitter="i18n.contact.contactLinks.twitter"
          :youtube="i18n.contact.contactLinks.youtube"
          :website="i18n.contact.contactLinks.website"
        />
      </div>
    </section>

    <section
      v-if="i18n.contact.disclaimer.active"
      class="content-grid"
      aria-label="Legal disclaimers"
    >
      <div class="col-span-full lg:col-span-14 lg:col-start-2 text-center">
        <p class="text-sm">
          {{ i18n.contact.disclaimer.disclaimer }}
        </p>
        <div class="dlp-social">
          <a>
            <svg class="icon-realtor"><use xlink:href="#icon-realtor" /></svg>
          </a>
          <a>
            <svg class="icon-lender"><use xlink:href="#icon-equal-housing" /></svg>
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'ContactContainer',
  data() {
    return {
      publicPath: process.env.BASE_URL,
      i18n: {
        agent: window.appData.agent,
        contact: window.appData.contact,
      },
    };
  },
  computed: {
    streetAddress() {
      return [this.i18n.agent.officeLocation.address1, this.i18n.agent.officeLocation.address2].filter(addressPart => addressPart && addressPart.length).join(', ');
    },
    cityStateZip () {
      const retStr = (this.i18n.agent.officeLocation.city || '')
          + (this.i18n.agent.officeLocation.city && this.i18n.agent.officeLocation.state ? ', ' : '')
          + (this.i18n.agent.officeLocation.state || '')
          + ' '
          + (this.i18n.agent.officeLocation.zip || '');
      return retStr.trim();
    },
  },
};
</script>
