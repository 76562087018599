<template>
    <div class="section-content" v-if="i18n.active">
        <div class="content-grid">
            <div class="order-1 md:order-2 col-span-full md:col-span-6 lg:col-span-7 lg:col-start-9">
              <dlp-figure
                  :sm="i18n.image"
                  :alt="i18n.alt"
                  ar="ar ar--4-3"
                  animate="shrink"
              />
            </div>
            <div class="order-2 md:order-1 col-span-full md:col-span-6 lg:col-span-6 lg:col-start-2">
                <h5 class="head-5 accent--above-left fade-in"> {{ i18n.heading }}</h5>
                <p class="fade-in"> {{ i18n.content }}</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "communityContainer",
    data(){
      return {
        publicPath: process.env.BASE_URL,
        i18n: window.appData.community,
      }
    }
}
</script>
