<template>
    <div class="section-content">
        <section v-if="i18n.slider.active" id="marketing-assets" class="content-grid">
            <div class="col-span-full text-center">
                <h3 class="head-2 accent--below-center accent--mb-0">{{ i18n.slider.heading }}</h3>
            </div>
            <div class="col-span-full text-center lg:mt-4 md:col-span-12 lg:col-span-14 lg:col-start-2">

                <dlp-carousel
                    :options="{spaceBetween: 64}"
                    :hasScrollNav="true"
                    >
                    <swiper-slide
                        v-for="card in i18n.slider.cards"
                        :key="card.id"
                    >
                        <dlp-display-card
                            :source="card.image"
                            :caption="card.caption"
                            :description="card.description"
                        />
                    </swiper-slide>
                </dlp-carousel>
            </div>
        </section>

    </div>
</template>

<script>
export default {
    name: 'buyingContainer',
    data: () => ({
      i18n: window.appData.buying,
    }),
    methods: {
        showModal: function(name, evt) {
            if(this.$refs[name]){
                this.$refs[name].open(evt)
            }
        },
    }
}
</script>
