<template>
  <figure
    class="dlp-display-card"
    v-bind="$attrs"
  >
    <div :class="[{ 'dlp-display-card-content-full': isFullSizeCard }, {'dlp-display-card-content': !isFullSizeCard}]">
      <div :class="[{ 'ar': ar }, ar]">
        <img
          :src="source"
          :alt="caption"
          :style="imgstyle"
        >
      </div>
    </div>
    <figcaption>
      <span
        v-if="caption"
        class="dlp-bio-card-caption accent--above-center head-6"
      >{{ caption }}</span>
    </figcaption>
    <figcaption>
      <p
        v-if="description"
        class="text-center mt-10"
      >{{ description }}</p>
    </figcaption>
  </figure>
</template>
<script>
export default {
  name: 'DlpDisplayCard',
  props: {
    source: {
      type: String,
    },
    href: {
      type: String,
    },
    click: {
      type: Function,
    },
    caption: {
      type: String,
    },
    description: {
      type: String,
    },
    imgstyle: {
      type: String,
    },
    ar: {
      type: String,
    },
    isFullSizeCard: {
      type: Boolean,
    },
  },
};
</script>
