<template>
  <header id="dlp-title">
    <div class="title title--style-dark">
      <img
          :class="[{ 'hide': isAnimated}]"
          class="intro-image"
          ref="introBg"
          :src="titleBackgroundUrl"
          role="presentation"/>
      <div class="intro-title">
        <div :class="logo.class">
          <img
              :class="[{ 'hide': isAnimated}]"
              id="pt-logo"
              ref="introLogo"
              width="320"
              height="66"
              :src="logo.source"/>
        </div>
        <div class="self-center">
          <div class="grid grid-col-1 gap-12">
            <div class="self-center animation-container">
              <h1 class="static-title" v-if="isStaticTitle">{{ staticTitleText }}</h1>
              <div id="bm-animation" v-if="!isStaticTitle" class="mx-auto" :class="animationClass"></div>
            </div>
            <div class="self-center">
              <h6 ref="introSubHead" class="head-6" :class="[{ 'hide': isAnimated }]">{{ presentationTitle }}</h6>
            </div>
          </div>
        </div>
        <a href="#agent" class="self-end scroll__wrapper" ref="scrollWrapper" :class="[{ 'hide': isAnimated }]">
          <p class="scroll">Scroll to continue</p>
          <img
              alt="Down arrow"
              ref="iconArrow"
              class="mx-auto"
              :class="[{ 'hide': isAnimated }]"
              role="presentation"
              :src="arrowImageUrl">
        </a>
      </div>
    </div>
  </header>
</template>

<script>
import gsap from 'gsap';
import bodymovin from 'lottie-web';
import {hasMotion} from '../constants'
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock'

export default {
  name: 'titleContainer',
  data() {
    return {
      publicPath: process.env.BASE_URL,
      i18n: {
        'agent': window.appData.agent,
        'team': window.appData.team,
        'coagent': window.appData.coagent,
      }
    }
  },
  mounted() {
    if (hasMotion) {
      this.lockScroll()

      bodymovin.loadAnimation({
        container: document.getElementById('bm-animation'),
        renderer: 'svg',
        loop: false,
        autoplay: true,
        path: window.appData.title.animationUrl,
      });

      gsap.fromTo(this.$refs.introBg,
          {
            scale: 1.5
          }, {
            scale: 1,
            ease: "expoScale(1.5, 1)",
            duration: 10
          }, 0);

      this.tl = gsap.timeline({onComplete: this.unlockScroll});

      this.tl.fromTo(this.$refs.introLogo, {
        opacity: 0,
        // y: 50
      }, {
        y: 0,
        delay: 0.7,
        opacity: 1
      }, 0)

      this.tl.fromTo(this.$refs.introSubHead, {
        opacity: 0,
        y: 50
      }, {
        delay: 0.7,
        opacity: 1,
        y: 0,
        duration: 0.7
      }, 2)

      this.tl.fromTo(this.$refs.scrollWrapper, {
        opacity: 0,
        y: 50
      }, {
        opacity: 1,
        y: 0,
        duration: 0.7,
      }, 3)

      this.tl.fromTo(this.$refs.iconArrow, {
        opacity: 0,
      }, {
        opacity: 1,
        y: 0,
        duration: 0.7,
      }, 3),

          //bounce scroll arrow
          gsap.to(this.$refs.iconArrow, {
            y: 10,
            duration: 0.7,
            stagger: {yoyo: true, repeat: -1}
          });

    }
  },
  methods: {
    lockScroll() {
      disableBodyScroll(this.$el)
    },
    unlockScroll() {
      enableBodyScroll(this.$el)
    }
  },
  computed: {
    isAnimated: function () {
      return hasMotion
    },
    titleBackgroundUrl: function () {
      return window.appData.title.backgroundUrl;
    },
    animationClass: function () {
      return window.appData.title.animationClass;
    },
    arrowImageUrl: function () {
      return window.appData.title.arrowImageUrl;
    },
    presentationTitle: function() {
      return window.appData.title.title;
    },
    staticTitleText: function() {
      return window.appData.title.staticTitleText;
    },
    isStaticTitle: function() {
      return window.appData.title.isStaticTitle;
    },
    logo: function() {
      return window.appData.title.logo;
    },
  }
}
</script>

<style lang="scss" scoped>
.static-title {
  font-size: 2.875rem !important;
  @apply uppercase;
}

.hide {
  opacity: 0;
}

#pt-logo {
  margin: 0 auto;
  width: 100%;
}

.scroll__wrapper {
  text-decoration: none;
}

p.scroll {
  font-size: theme('fontSize.xs');
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.title {
  position: relative;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  @media only screen and (max-device-width: theme('screens.sm')) {
    height: calc(100vh - 48px);
  }

  .intro-image,
  .intro-title {
    position: absolute;
    top: 0;
    left: 0;
  }

  .intro-image {
    object-fit: cover;
    object-position: center center;
    opacity: 0.5;
    width: 100%;
    height: 100%;
  }

  .intro-title {
    margin: 8px;
    border: 1px solid theme('colors.neutral.500');
    width: calc(100% - 18px);
    height: calc(100% - 18px);
    padding: 20px;
    display: grid;
    justify-items: center;
    text-align: center;

    @media (min-width: theme('screens.md')) {
      margin: 16px;
      width: calc(100% - 34px);
      height: calc(100% - 34px);
    }
    @media (min-width: theme('screens.lg')) {
      margin: 20px;
      width: calc(100% - 42px);
      height: calc(100% - 42px);
    }
  }

  .icon-arrow {
    fill: none;
    stroke: theme('colors.secondary.500') !important;
    stroke-linejoin: round;
    stroke-width: 2;
    height: 64px;
    margin: 0 auto;
    padding-top: 16px;
    opacity: 0;
    transform: translate(0, -20px);
  }
}

.title--style-dark {
  background: linear-gradient(to top, black, theme('colors.primary.900'));

  h1,
  h2, h3, h4, h5, h6, p {
    color: theme('colors.white');
  }

  #pt-logo {
    fill: theme('colors.white');
  }

  #pt-logo-at {
    fill: theme('colors.secondary.500');
  }

  #love {
    stroke: theme('colors.white');
  }
}

.title--style-light {
  background: linear-gradient(to top, white, theme('colors.primary.100'));

  h1, h2, h3, h4, h5, h6, p {
    color: theme('colors.primary.900');
  }

  #pt-logo {
    fill: theme('colors.primary.900');
  }

  #pt-logo-at {
    fill: theme('colors.secondary.500');
  }

  #love {
    stroke: theme('colors.primary.900');
  }
}

#love {
  display: block;
  margin: 0 auto;
  width: 50vw;

  @media (min-width: theme('screens.md')) {
    width: 30vw;
  }
}

.animation-container {
  width:100%;
  @media (min-width: theme('screens.lg')) {
    width: 800px;
  }
}
</style>
