<template>
    <div class="section-content">

        <section v-if="i18n.unique.active" id="company-origin" class="content-grid">
            <div class="col-span-full md:col-span-6 lg:col-span-7 lg:col-start-2">
                <dlp-figure
                    :lg=i18n.originImage.lgImage
                    :alt=i18n.originImage.alt
                    ar="ar ar--5-4"
                    animate="shrink"
                />
            </div>
            <div class="col-span-full md:col-span-6 md:col-start-7 lg:col-span-6 lg:col-start-10 self-center">
                <h3 class="head-5 accent--above-left">{{ i18n.unique.heading }}</h3>
                <p v-html="i18n.unique.content"></p>
            </div>
        </section>

        <section v-if="i18n.timeline.active" id="company-timeline" class="content-grid">
            <div class="col-span-full text-center">
              <h3 class="head-2 accent--below-center accent--mb-0">{{i18n.timeline.timelineHeading}}</h3>
            </div>
            <div class="col-span-full text-center lg:mt-4 md:col-span-12 lg:col-span-14 lg:col-start-2">
                <dlp-timeline>
                    <swiper-slide v-for="timelineItem in i18n.timeline.timeline" :key="timelineItem.id">
                        <dlp-timeline-item  
                            :date=timelineItem.date
                            :alt=timelineItem.alt
                            :img=timelineItem.image
                            :desc=timelineItem.description
                            ar="ar ar--3-2"
                            />
                    </swiper-slide>
                </dlp-timeline>
            </div>
        </section>

        <section v-if="i18n.stats.active" id="company-stats" class="content-grid">
          <div class="col-span-full text-center">
            <h3 class="head-2 accent--below-center accent--mb-0">{{ i18n.stats.statsHeading }}</h3>
          </div>
            <div class="col-span-full text-center md:col-span-12 lg:col-span-14 lg:col-start-2">
            <dlp-stat-grid :stats="i18n.stats.companyStats"/>
          </div>
        </section>

      <section
        v-if="i18n.video.active"
        id="company-video"
        class="content-grid"
      >
        <div class="col-span-full text-center">
          <h3 class="head-2 accent--below-center accent--mb-0">
            {{ i18n.video.heading }}
          </h3>
        </div>
        <div class="col-span-full lg:col-span-12 lg:col-start-3 lg:mt-4">
          <dlp-video-card
            v-if="i18n.video.video"
            :sm="i18n.video.image"
            :alt="i18n.video.alt"
            animate="shrink"
            :url="i18n.video.video"
            :title="i18n.video.title"
          />
          <dlp-figure
            v-if="!i18n.video.video"
            :sm="i18n.video.image"
            :alt="i18n.video.alt"
            ar="ar ar--4-3"
            animate="shrink"
          />
        </div>
        <div class="col-span-full md:col-span-8 md:col-start-3 lg:col-span-6 lg:col-start-6">
          <p class="accent--above-left">
            {{ i18n.video.content }}
          </p>
        </div>
      </section>

    </div>
</template>

<script>
import StatGrid from './statGrid.container'
export default {
    name: 'companyContainer',
    data(){
        return {
            i18n: window.appData.company,
            publicPath: process.env.BASE_URL
        }
    },
    components: {
        [StatGrid.name]: StatGrid
    },
    methods: {
        showModal: function(name, evt) {
            if(this.$refs[name]){
                this.$refs[name].open(evt)
            }
        }
    }
}
</script>
