<template>
    <div class="col-span-full lg:col-span-14 lg:col-start-2">
        <div class="stat-img__grid">
            <div class="stat-img__tile" v-for="stat in stats" :key="stat.id">
                <figure>
                    <picture class="ar ar--stat">
                        <img :src=stat.image alt="" aria-hidden="true" />
                    </picture>
                </figure>
                <div class="stat-img__border">
                    <div class="stat-img__content">
                        <div class="stat">
                            <span class="head-3">{{ stat.heading }}</span>
                            <span class="head-6">{{ stat.content }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'dlp-stat-grid',
    props: {
        stats: Object,
    }
}
</script>