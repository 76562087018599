<template>
    <div class="section-content">

      <section
        v-if="i18n.mortgage.active"
        class="content-grid"
      >
        <div class="order-3 md:order-4 col-span-4 md:col-span-6 md:col-start-7 lg:col-span-7 lg:col-start-9">
          <dlp-figure
            :sm="i18n.mortgage.image"
            :alt="i18n.mortgage.alt"
            ar="ar ar--4-3"
            animate="shrink"
          />
        </div>
        <div
          class="order-4 md:order-3 col-span-4 md:col-span-6 md:col-start-1 lg:col-span-6 lg:col-start-2 content-center self-center"
        >
          <h3 class="head-5 accent--above-left fade-in">
            {{ i18n.mortgage.heading }}
          </h3>
          <p class="fade-in">
            {{ i18n.mortgage.content }}
          </p>
          <div
            v-if="i18n.mortgage.button.active"
            class="btn-group fade-in"
          >
            <dlp-button
              type="button"
              @click="openPdf()"
            >
              {{ i18n.mortgage.button.buttonText }}
            </dlp-button>
          </div>
        </div>
      </section>

      <section class="content-grid" v-if="i18n.officer.active">
        <div class="col-span-full px-12 sm:px-0 sm:col-span-2 md-col-start:1 md:col-span-3 md-col-start:1 lg:col-start-2 lg:col-span-4 content-center self-center">
          <dlp-figure
            :sm="`${i18n.officer.loanOfficer.profilePhoto}`"
            :alt="`${i18n.officer.loanOfficer.name}`"
            ar="ar ar--4-5"
            :is-sticky="true"
            animate="shrink"
          />
        </div>
        <div class="col-span-full md:col-start-4 md:col-span-5 lg:col-start-6 lg:col-span-6 content-center self-center">
          <h5 class="head-5 fade-in">{{ i18n.officer.heading }}</h5>
          <p class="fade-in">{{ i18n.officer.loanOfficer.name }}</p>
          <p class="fade-in mt-0">{{ i18n.officer.loanOfficer.title }}</p>
          <p class="fade-in mt-0">{{ i18n.officer.loanOfficer.mobile }}</p>
          <p class="fade-in mt-0">{{ i18n.officer.loanOfficer.email }}</p>
        </div>
        <div class="col-span-full md:col-span-2 lg:col-start-12 lg:col-span-3 content-center self-center">
          <img
            class="mx-auto logo--agency fade-in"
            :alt="`${i18n.officer.logo.alt}`"
            :src="`${i18n.officer.logo.image}`"
          >
        </div>
      </section>

    </div>
</template>


<script>
export default {
    name: 'loanContainer',
    data: () => ({
      i18n: window.appData.loan,
    }),
    methods: {
        showModal: function(name, evt) {
            if(this.$refs[name]){
                this.$refs[name].open(evt)
            }
        },
        openPdf: function() {
            window.open(this.i18n.mortgage.button.pdf, '_blank');
        }
    }
}
</script>
