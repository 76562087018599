<template>
    <div class="section-content">
        <section v-if="i18n.slider.active" id="marketing-assets" class="content-grid">
            <div class="col-span-full text-center">
                <h3 class="head-2 accent--below-center accent--mb-0">{{ i18n.slider.heading }}</h3>
            </div>
            <div class="col-span-full text-center lg:mt-4 md:col-span-12 lg:col-span-14 lg:col-start-2">

                <dlp-carousel
                    :options="{spaceBetween: 64}"
                    :hasScrollNav="true"
                    >
                    <swiper-slide
                        v-for="card in i18n.slider.cards"
                        :key="card.id"
                    >
                        <dlp-display-card
                            v-if="reach.portfolio.showLuxury || !card.isLuxury"
                            :source="card.image"
                            :caption="card.caption"
                            :description="card.description"
                        />
                    </swiper-slide>
                </dlp-carousel>
            </div>
        </section>

        <section id="listing-video" class="content-grid" v-if="i18n.video.visible && i18n.video.active">
            <div class="col-span-full text-center">
                <h3 class="head-2 accent--below-center accent--mb-0">{{ i18n.video.heading }}</h3>
            </div>
            <div class="col-span-full lg:col-span-12 lg:col-start-3 lg:mt-4">
                <dlp-video-card v-if="i18n.video.video"
                    :sm="i18n.video.image"
                    :alt="i18n.video.alt"
                    animate="shrink"
                    :url="i18n.video.video"
                    :title="i18n.video.title"
                />
                <dlp-figure v-if="!i18n.video.video"
                    :sm="i18n.video.image"
                    :alt="i18n.video.alt"
                    ar="ar ar--4-3"
                    animate="shrink"
                />
            </div>
            <div class="col-span-full md:col-span-8 md:col-start-3 lg:col-span-6 lg:col-start-6">
                <p class="accent--above-left">{{ i18n.video.content }}</p>
            </div>
        </section>

        <section id="signage" v-if="i18n.signage.visible && i18n.signage.active" class="content-grid">
            <div class="col-span-full text-center">
                <h3 class="head-2 accent--below-center accent--mb-0">{{ i18n.signage.heading }}</h3>
            </div>
            <div class="col-span-full lg:col-span-10 lg:col-start-4 lg:mt-4">
                <dlp-video-card v-if="i18n.signage.video"
                    :sm="i18n.signage.image"
                    alt="i18n.signage.alt"
                    animate="shrink"
                    :url="i18n.signage.video"
                    title="i18n.signage.title"
                />
                <dlp-figure v-if="!i18n.signage.video"
                    :sm="i18n.signage.image"
                    :alt="i18n.signage.alt"
                    ar="ar ar--4-3"
                    animate="shrink"
                />
            </div>
            <div class="col-span-full md:col-span-8 md:col-start-3 lg:col-span-6 lg:col-start-6">
                <p class="accent--above-left">{{ i18n.signage.content }}</p>
            </div>
        </section>

        <section v-if="i18n.tailored.active" id="marketing-plan" class="content-grid">
            <div class="col-span-full text-center">
                <h3 class="head-2 accent--below-center accent--mb-0">{{ i18n.tailored.heading }}</h3>
            </div>
            <div class="col-span-full md:col-span-6 lg:col-span-7 lg:col-start-2">
                <dlp-figure
                    :sm="i18n.tailored.image"
                    :alt="i18n.tailored.alt"
                    ar="ar ar--4-3"
                    animate="shrink"
                />
            </div>
            <div class="col-span-full md:col-span-6 md:col-start-7 md:content-center lg:col-span-6 lg:col-start-10 content-center self-center">
                <p class="accent--above-left fade-in">{{ i18n.tailored.content }}</p>
                <div class="btn-group fade-in">
                    <dlp-button v-if="i18n.tailored.customMarketingPlan"
                                type="button"
                                @click="openMarketingPlan()">
                      {{ i18n.tailored.modal.buttonText }}</dlp-button>
                </div>
                <dlp-appear-modal ref="MarketingPlanModal">
                    <h3 class="head-5 accent--above-left mb-8">{{ i18n.tailored.modal.heading }}</h3>
                    <p>{{ i18n.tailored.modal.content }}</p>
                </dlp-appear-modal>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'marketingContainer',
    data: () => ({
      i18n: window.appData.marketing,
      reach: window.appData.reach,
    }),
    methods: {
        showModal: function(name, evt) {
            if(this.$refs[name]){
                this.$refs[name].open(evt)
            }
        },
        openMarketingPlan: function() {
            window.open(this.i18n.tailored.customMarketingPlan, '_blank')
        },
    }
}
</script>
