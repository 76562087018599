<template>
<div>
  <!--    <DevGrid />&lt;!&ndash; Remove this for production. Development only to check alignment. &ndash;&gt;-->

  <TitleContainer/>

  <Nav/>

  <main class="dlp-main">
    <article :id="appData.menu.slugs.team" class="section-divider" v-if="appData.teamBeforeAgent">
      <TeamContainer/>
    </article>

    <article :id="appData.menu.slugs.agent" class="section-divider">
      <AgentContainer/>
    </article>

    <article :id="appData.menu.slugs.coagent" class="section-divider">
      <CoagentContainer/>
    </article>

    <article :id="appData.menu.slugs.team" v-if="!appData.teamBeforeAgent">
      <TeamContainer/>
    </article>

    <article :id="appData.menu.slugs.company" v-if="appData.company.sectionActive">
      <TitlePage
        v-if="appData.company.title.active"
        wrapperRef="companyTitle"
        :backgroundUrl="appData.company.title.backgroundImage"
        :heading="appData.company.title.heading"
        :subheading="appData.company.title.subheading"/>
      <CompanyContainer/>
    </article>

    <article :id="appData.menu.slugs.technology" v-if="appData.technology.sectionActive">
      <TitlePage
        v-if="appData.technology.title.active"
        wrapperRef="technologyTitle"
        :backgroundUrl="appData.technology.title.backgroundImage"
        :heading="appData.technology.title.heading"
        :subheading="appData.technology.title.subheading"/>
      <TechnologyContainer/>
    </article>

    <article :id="appData.menu.slugs.buying" v-if="appData.buying.sectionActive">
      <TitlePage
        v-if="appData.buying.title.active"
        wrapperRef="buyingTitle"
        :backgroundUrl="appData.buying.title.backgroundImage"
        :heading="appData.buying.title.heading"
        :subheading="appData.buying.title.subheading"/>
      <BuyingContainer/>
    </article>

    <article :id="appData.menu.slugs.luxury" v-if="appData.loan.sectionActive">
      <TitlePage
        v-if="appData.loan.title.active"
        wrapperRef="loanTitle"
        :backgroundUrl="appData.loan.title.backgroundImage"
        :heading="appData.loan.title.heading"
        :subheading="appData.loan.title.subheading"/>
      <LoanContainer/>
    </article>

    <article :id="appData.menu.slugs.closing" v-if="appData.closing.sectionActive">
      <TitlePage
        v-if="appData.closing.title.active"
        wrapperRef="closingTitle"
        :backgroundUrl="appData.closing.title.backgroundImage"
        :heading="appData.closing.title.heading"
        :subheading="appData.closing.title.subheading"/>
      <ClosingContainer/>
    </article>

    <article :id="appData.menu.slugs.partners" v-if="appData.partners.sectionActive">
      <TitlePage
        v-if="appData.partners.title.active"
        wrapperRef="partnersTitle"
        :backgroundUrl="appData.partners.title.backgroundImage"
        :heading="appData.partners.title.heading"
        :subheading="appData.partners.title.subheading"/>
      <PartnersContainer/>
    </article>

    <article :id="appData.menu.slugs.community" v-if="appData.community.sectionActive">
      <TitlePage
        v-if="appData.community.title.active"
        wrapperRef="communityTitle"
        :backgroundUrl="appData.community.title.backgroundImage"
        :heading="appData.community.title.heading"
        :subheading="appData.community.title.subheading"/>
      <CommunityContainer/>
    </article>
  </main>

  <div class="section-divider"></div>

  <footer id="contact" v-if="appData.contact.sectionActive">
    <ContactContainer/>
  </footer>

</div>
</template>

<script>
import Nav from "@/components/nav/nav.component.vue";
import TitleContainer from "@/containers/title.container.vue";
import AgentContainer from "@/containers/agent.container.vue";
import CoagentContainer from "@/containers/coagent.container.vue";
import TeamContainer from "@/containers/team.container.vue";
import CompanyContainer from "@/containers/company.container.vue";
import BuyingContainer from "@/containers/buying.container.vue";
import ContactContainer from "@/containers/contact.container.vue";
import TechnologyContainer from "@/containers/technology.container.vue";
import LoanContainer from "@/containers/loan.container.vue";
import ClosingContainer from "@/containers/closing.container.vue";
import CommunityContainer from "@/containers/community.container.vue";
import PartnersContainer from "@/containers/partner.container.vue";
import TitlePage from "@/components/title/title.page.vue";

export default {
  name: 'buyerPresentationContainer',
  components: {
    Nav,
    TitleContainer,
    AgentContainer,
    CoagentContainer,
    TeamContainer,
    CompanyContainer,
    BuyingContainer,
    ContactContainer,
    TechnologyContainer,
    LoanContainer,
    ClosingContainer,
    CommunityContainer,
    PartnersContainer,

    TitlePage,
    // DevGrid
  },
  data() {
    return {
      appData: window.appData,
    }
  },
}
</script>

<style scoped>

</style>