<template>
  <div
    v-if="i18n.active"
    class="section-content"
  >
    <div class="content-grid">
      <div class="col-span-full md:col-span-8 md:col-start-3 lg:col-span-6 lg:col-start-6">
        <h5 class="head-5 accent--above-left fade-in">
          {{ i18n.heading }}
        </h5>
        <p class="fade-in">
          {{ i18n.content }}
        </p>
      </div>

      <div class="col-span-full text-center mt-8 partner-container">
        <DlpCarousel
          :options="{
            spaceBetween: 32,
          }"
          :has-scroll-nav="true"
        >
          <SwiperSlide
            v-for="partner in i18n.partners"
            :key="partner.id"
          >
            <DlpDisplayCard
              :source="partner.image"
              :is-full-size-card="i18n.isFullSizeCard"
              :imgstyle="'width:' + partner.width"
            />
          </SwiperSlide>
        </DlpCarousel>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PartnersContainer',
  data: () => ({
    i18n: window.appData.partners,
  }),
};
</script>
