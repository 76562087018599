<template>
  <scrollactive
    active-class="isActive"
    :offset="400"
    :modify-url="false"
    :click-to-scroll="false"
    :highlight-first-item="true"
    :scroll-on-start="false"
    :class="{ isOpen: isOpen}"
    :always-track="true"
    class="dlp-nav"
    aria-label="Main Menu"
    @itemchanged="onItemChanged"
  >
    <div class="dlp-nav-tray xl:container mx-auto">
      <a
        class="dlp-nav-m-tile flex lg:items-center"
        href="#"
        @click="resetHighlight"
      >
        <img
          class="dlp-nav-logo"
          height="26"
          width="130"
          :alt="logo.alt"
          :src="logo.src"
        >
        <span class="visually-hidden">Back to top</span>
      </a>
      <div
        id="nav-menu-links"
        ref="navMenu"
        class="dlp-nav-menu"
      >
        <div
          ref="navUlWrapper"
          class="text-center"
        >
          <h2
            ref="navUlHeader"
            class="head-1 mb-6"
          >
            Menu
          </h2>
          <ul
            ref="navUl"
            class="dlp-nav-ul"
          >
            <li
              v-for="navLink in appData.menu.navLinks"
              :key="navLink.slug"
            >
              <a
                class="dlp-nav-link scrollactive-item"
                :href="`#` + navLink.slug"
                :data-scrollto="navLink.scrollTo"
                @click="clickNav"
                v-text="navLink.name"
              />
            </li>
            <li
              ref="highlight"
              role="presentation"
              class="hidden opacity-0"
              :class="{'dlp-nav-highlight': isMounted}"
            />
          </ul>
        </div>
      </div>
      <div class="dlp-nav-trigger dlp-nav-m-tile flex lg:hidden">
        <button
          id="nav-icon"
          aria-expanded="false"
          :class="{ open: isOpen}"
          @click="toggle"
        >
          <span class="nav-icon__wrapper">
            <span class="nav-icon-line line-1" />
            <span class="nav-icon-line line-2" />
            <span class="nav-icon-line line-3" />
            <span class="nav-icon-line line-4" />
          </span>
          <span class="nav-icon-text">Menu</span>
        </button>
      </div>
      <div class="dlp-nav-contact dlp-nav-m-tile flex">
        <button @click="showModal('ContactModal')">
          Contact Me
        </button>
      </div>
      <dlp-appear-modal ref="ContactModal">
        <h3 class="head-5 accent--above-left mb-2">
          {{ i18n.name }}
        </h3>
        <h6
          v-if="i18n.showAgentTitle && (i18n.title && i18n.title.length)"
          class="head-6"
        >
          {{ i18n.title }}
        </h6>
        <p
          v-if="i18n.showDesignations && (i18n.designationList && i18n.designationList.length)"
          class="mt-0"
        >
          <small>
            {{ i18n.designationList }}
          </small>
        </p>
        <p
          v-if="i18n.licenses && i18n.licenses.length"
          class="mt-0"
        >
          <small>
            License No. {{ i18n.licenses }}
          </small>
        </p>
        <address class="contact-info">
          <a :href="`tel:+1-${i18n.contact.phone}`">{{ i18n.contact.phone }}</a>
          <a :href="`mailto:${i18n.contact.email}`">{{ i18n.contact.email }}</a>
        </address>
        <dlp-social
          :facebook="i18n.contact.social.facebook"
          :instagram="i18n.contact.social.instagram"
          :twitter="i18n.contact.social.twitter"
          :youtube="i18n.contact.social.youtube"
          :website="i18n.contact.social.website"
        />

        <div v-if="i18n.showOfficeInfo">
          <h5 class="head-5 mt-4">
            Office Location
          </h5>
          <p
            class="mt-0"
            v-text="streetAddress"
          />
          <p
            class="mt-0"
            v-text="cityStateZip"
          />
          <a
            class="mt-0"
            :href="`tel:+1-${i18n.officePhone}`"
          >
            <p class="mt-0">{{ i18n.officePhone }}</p>
          </a>
        </div>
      </dlp-appear-modal>
    </div>
  </scrollactive>
</template>

<script>
import gsap from 'gsap';
import { debounce, delay, initial } from 'lodash';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

export default {
  name: 'DlpNavComponent',
  data: () => ({
    isMounted: false,
    isOpen: false,
    isScrolling: false,
    elActive: null,
    i18n: window.appData.agent,
    appData: window.appData,
    logo: {
      src: window.appData.contact.logo,
      alt: window.appData.contact.alt,
    },
  }),
  computed: {
    streetAddress() {
      return [this.i18n.officeLocation.address1, this.i18n.officeLocation.address2].filter(addressPart => addressPart && addressPart.length).join(', ');
    },
    cityStateZip() {
      const retStr = (this.i18n.officeLocation.city || '')
          + (this.i18n.officeLocation.city && this.i18n.officeLocation.state ? ', ' : '')
          + (this.i18n.officeLocation.state || '')
          + ' '
          + (this.i18n.officeLocation.zip || '');
      return retStr.trim();
    },
  },
  unmounted() {
    window.removeEventListener('scroll', this.bounceScroll);
    window.removeEventListener('resize', this.bounceResize);
  },
  mounted() {
    // On load, posititon the highlight. Then allow render
    const initActive = this.$refs.navUl.querySelector('.isActive');
    // set the stored active item
    if (initActive) {
      this.elActive = initActive;
    } else {
      this.elActive = this.$refs.navUl.children[0].children[0];
    }
    delay(() => {
      // set the styles to match nav item
      this.$refs.highlight.style.width = this.getWidth(this.elActive);
      this.$refs.highlight.style.left = this.getLeft(this.elActive);
      // over precidence hidden on highlight
      this.isMounted = true;

      gsap.to(this.$refs.highlight, {
        opacity: 1,
      });
    }, 2000);

    delay(() => {
      this.bounceScroll = debounce(this.handleScroll, 800, { leading: true });
      window.addEventListener('scroll', this.bounceScroll);

      this.bounceResize = debounce(this.handleResize, 200);
      window.addEventListener('resize', this.bounceResize);
    }, 20);
  },
  methods: {
    open() {
      this.isOpen = true;
      const openTimeline = gsap.timeline({
        onComplete: () => {
          disableBodyScroll(this.$refs.navMenu);
        },
      });
      // Expand nav menu
      openTimeline.fromTo(this.$refs.navMenu, {
        top: window.innerHeight,
      }, {
        duration: 1,
        top: 0,
      });
      // fade in header
      openTimeline.from(this.$refs.navUlHeader, {
        duration: 0.5,
        opacity: 0,
      });
      // reveal each nav item
      openTimeline.fromTo(initial(this.$refs.navUl.children), {
        opacity: 0,
      }, {
        duration: 0.5,
        stagger: 0.2,
        opacity: 1,
      });
    },
    close() {
      const closeTimeline = gsap.timeline();
      clearAllBodyScrollLocks();
      // fade the links
      closeTimeline.to(this.$refs.navUlWrapper, {
        opacity: 0,
      });
      // swipe the menu
      closeTimeline.to(this.$refs.navMenu, {
        delay: 1,
        top: window.innerHeight,
        onComplete: () => {
          this.isOpen = false;
        },
      });
      // cleanup styles
      closeTimeline.set(this.$refs.navMenu, { clearProps: 'all' });
      closeTimeline.set(this.$refs.navUlWrapper, { clearProps: 'all' });
    },
    toggle() {
      if (this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    },
    clickNav(evt) {
      this.elActive = evt.target;

      if (this.elActive.dataset.scrollto) {
        evt.preventDefault();
      }

      if (this.elActive.dataset.scrollto) {
        const scrollToElement = document.getElementById(this.elActive.dataset.scrollto);

        if (scrollToElement.getBoundingClientRect().y < 0.4) { // y coord of screen focused on pinned element isn't quite 0px, need a little wriggle room
          scrollToElement.scrollIntoView(false);
        } else {
          scrollToElement.scrollIntoView();
          window.scroll(scrollX, scrollY + scrollToElement.offsetHeight);
        }
      }

      if (this.isOpen) {
        this.close();
      }
    },
    // update the highlight on scroll
    // eslint-disable-next-line no-unused-vars
    onItemChanged(evt, currentItem, lastItem) {
      if (!currentItem) return;
      this.elActive = currentItem;
      delay(this.changeHighlight, 200);
    },
    // what is the left offest per the parent container
    getLeft(target) {
      return `${target.offsetLeft}px`;
    },
    // what is the width of the new nav item?
    getWidth(target) {
      const activeState = target.getBoundingClientRect();
      return `${activeState.width}px`;
    },
    changeHighlight() {
      if (!this.elActive) return;
      gsap.to(this.$refs.highlight, {
        width: this.getWidth(this.elActive),
        left: this.getLeft(this.elActive),
      });
    },
    // did we resize? Reset the line
    handleResize() {
      this.changeHighlight();
      // edge case. if the nav is open and resize happens, release the lock
      clearAllBodyScrollLocks();
    },
    // Are we scrolling?
    handleScroll() {
      this.isScrolling = true;
      delay(() => {
        this.isScrolling = false;
      }, 800);
    },
    // send the highlight back to the first nav item
    resetHighlight() {
      this.elActive = this.$refs.navUl.children[0].children[0];
      this.changeHighlight();
    },
    showModal(name) {
      if (this.$refs[name]) {
        this.$refs[name].open();
      }
    },
  },
};
</script>
