<template>
  <div
    class="dlp-carousel"
    v-bind="$attrs"
  >
    <Swiper
      ref="dlpSwiper"
      :options="swiperOptions"
      class="dlp-carousel-items"
      @progress="scrollLockCheck"
    >
      <slot />
      <template #button-prev>
        <div
          :id="`dlp-slider-prev-${swiperId}`"
          class="swiper-button-prev swiper-nav-button"
        >
          <svg>
            <use xlink:href="#slider-nav-arrow" />
          </svg>
        </div>
      </template>
      <template #button-next>
        <div
          :id="`dlp-slider-next-${swiperId}`"
          class="swiper-button-next swiper-nav-button"
        >
          <svg>
            <use xlink:href="#slider-nav-arrow" />
          </svg>
        </div>
      </template>
    </Swiper>
    <div
      v-if="hasScrollNav"
      :id="`dlp-carousel-nav-${swiperId}`"
      class="dlp-carousel-nav swiper-scrollbar"
    />
  </div>
</template>
<script>
export default {
  name: 'DlpCarousel',
  props: {
    options: {
      type: Object,
      required: false,
      default: null,
    },
    hasScrollNav: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    // https://github.com/surmon-china/vue-awesome-swiper/issues/717
    // Fix scrollbar when multiple swipers used
    const _id = 'swiper' + Math.round(Math.random() * 100000);
    return {
      swiperId: _id,
      swiperOptions: {
        ...this.options,
        slidesPerView: 'auto',
        grabCursor: true,
        centerInsufficientSlides: true,
        scrollbar: this.hasScrollNav
          ? {
              el: `#dlp-carousel-nav-${_id}`,
              draggable: true,
              dragSize: 100,
            }
          : false,
        navigation: {
          nextEl: `#dlp-slider-next-${_id}`,
          prevEl: `#dlp-slider-prev-${_id}`,
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.dlpSwiper.$swiper;
    },
  },
  methods: {
    scrollLockCheck(progress) {
      if (progress > 1.25) {
        this.$refs.dlpSwiper.$swiper.scrollbar.$dragEl[0].classList.add('swiper-scrollbar-lock');
      }
      if (progress <= 1.25) {
        this.$refs.dlpSwiper.$swiper.scrollbar.$dragEl[0].classList.remove('swiper-scrollbar-lock');
      }
    },
  },
};
</script>
